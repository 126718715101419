import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import i18n from '@/i18n'

Vue.use(Vuetify)

const theme = {
  primary: '#660834',
  secondary: '#689CC0',
  accent: '#011126',
  info: '#734D65',
  success: '#C94B45',
  blend: '#23798C',
  deep: '#658DA6',
  warning: '#cc0000',
  shout: '#FF91AB',
}

export default new Vuetify({
  lang: {
    t: (key, ...params) => i18n.t(key, params),
  },
  theme: {
    themes: {
      dark: theme,
      light: theme,
    },
  },
})
