import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    barColor: 'rgba(0, 0, 0, .8), rgba(0, 0, 0, .2)',
    barImage: 'BlueBend.jpeg',
    drawer: null,
    gexSession: null,
    gexUser: null,
    gexToken: localStorage.getItem('gexToken') || '',
    gexStatus: '',
    AUTH_REQUEST: ''
  },
  getters: {
    isAuthenticated: state => !!state.token,
    authStatus: state => state.status,
    GEXSESSION: (state) => {
      return state.gexSession
    },
    GEXUSER: (state) => {
      return state.gexUser
    } 
  },
  mutations: {
    SET_BAR_IMAGE (state, payload) {
      state.barImage = payload
    },
    SET_DRAWER (state, payload) {
      state.drawer = payload
    },
    SET_SCRIM (state, payload) {
      state.barColor = payload
    },
    SET_GEXSESSION (state, payload) {
      state.gexSession = payload
    },
    SET_GEXUSER (state, payload) {
      state.gexUser = payload
    },
  },
  actions: {
    SET_GEXSESSION: ({commit, state}, newValue) => {
      commit('SET_GEXSESSION', newValue)
      return state.gexSession
    },
    SET_GEXUSER: ({commit, state}, newValue) => {
      commit('SET_GEXUSER', newValue)
      return state.gexUser
    }
  },
})
